import {createSlice} from '@reduxjs/toolkit'

const cobPromptSlice = createSlice({
  name: 'library',
  initialState: {
    active: false,
  },
  reducers: {
    setCOBPrompt: (state, action) => {
      state.active = action?.payload
    },
  },
})

export const cobPromptReducer = cobPromptSlice.reducer
export const {setCOBPrompt} = cobPromptSlice.actions
