import {combineReducers} from 'redux'
import {loadingIndicatorReducer} from './loadingIndicatorSlice'
import {CLEAR_STATE} from '../../constants/reduxTypes'
import {addressReducer} from './addressSlice'
import {promptReducer} from './promptSlice'
import {configurationReducer} from './configurationSlice'
import {filterReducer} from './filterSlice'
import {submitDocumentsReducer} from './submitDocumentsSlice'
import {docuSignReducer} from './docuSignSlice'
import {modalReducer} from './modalSlice'
import {dependentReducer} from './dependentSlice'
import {beneficiaryReducer} from './beneficiarySlice'
import {caseReducer} from './caseSlice'
import {paymentReducer} from './paymentSlice'
import {enrollmentReducer} from './enrollmentSlice'
import {beneficiariesReducer} from './beneficiariesSlice'
import {cobPromptReducer} from './cobPrompt'

const appReducer = combineReducers({
  address: addressReducer,
  beneficiaries: beneficiariesReducer,
  beneficiary: beneficiaryReducer,
  case: caseReducer,
  configuration: configurationReducer,
  dependent: dependentReducer,
  docuSign: docuSignReducer,
  enrollment: enrollmentReducer,
  filter: filterReducer,
  submitDocuments: submitDocumentsReducer,
  loading: loadingIndicatorReducer,
  modal: modalReducer,
  payment: paymentReducer,
  prompt: promptReducer,
  cobPrompt: cobPromptReducer,
})

export default (state, action) => {
  if (action.type === CLEAR_STATE) {
    state = undefined
  }

  return appReducer(state, action)
}
